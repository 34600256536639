$resource-preview-height: 320px;
$resource-preview-height-small: 300px;

.c-resource-preview {
  @include padding(spacing(xx-small) spacing(x-small) spacing(x-small));
  border: $base-border;
  border-top: solid 3px $accent-color;
  border-radius: $base-border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: $resource-preview-height;
  height: 100%;
  width: 100%;

  @include breakpoint(small) {
    min-height: $resource-preview-height-small;
  }
}


.c-resource-preview__category {
  @include small-text-uppercase();
  color: $accent-color;
  font-size: modular-scale(-4);
  letter-spacing: 0.1rem;

  // @include breakpoint(small) {
  //   margin-bottom: spacing(xxxx-small);
  // }
}

.c-resource-preview__heading {
  @include margin(7px null spacing(xxx-small));
  font-size: modular-scale(3);
  line-height: 1.25;

  // @include breakpoint(small) {
  //   font-size: modular-scale(2);
  // }
}

.c-resource-preview__info {
  overflow: hidden;
  position: relative;
  transition: padding $base-duration $base-timing;

  .c-resource-preview--with-website:hover & {
    @media (hover: hover) {
      padding-bottom: spacing(small);
    }
  }

  .c-resource-preview--with-website & {
    @media (hover: none) {
      padding-bottom: spacing(small);
    }

    @include breakpoint(small) {
      padding-bottom: spacing(small);
    }
  }
}

.c-resource-preview__description {
  font-size: modular-scale(-1);
  line-height: $base-line-height;

  // @include breakpoint(small) {
  //   font-size: modular-scale(0);
  //   line-height: $base-line-height-small;
  // }
}

.c-resource-preview__link {
  bottom: -(spacing(small));
  display: inline-block;
  font-size: modular-scale(-1);
  margin-top: spacing(xxx-small);
  position: absolute;
  transition: bottom $base-duration $base-timing;

  .c-resource-preview--with-website:hover & {
    @media (hover: hover) {
      bottom: 0;
    }
  }

  .c-resource-preview--with-website & {
    @media (hover: none) {
      bottom: 0;
    }
    
    @include breakpoint(small) {
      bottom: 0;
    }
  }
}