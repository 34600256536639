.c-sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  padding-top: $sidebar-top-spacing;
  right: $global-side-spacing;
  width: $sidebar-width;
  z-index: $highest-z-index - 1;

  @include breakpoint(xx-large) {
    right: calc((#{100vw} - ( #{$global-body-max-width} - (#{$global-side-spacing} * 2) ) ) / 2);
  }

  @include breakpoint(large) {
    right: $global-side-spacing-large;
  }

  @include breakpoint(medium) {
    padding-top: 0;
    right: 0;
  }

  @include breakpoint(small) {
    padding-top: 0;
    right: 0;
  }
}

.c-sidebar--with-email-capture {
  @include breakpoint(medium) {
    height: auto;
    position: static;
    width: 100%;
  }

  @include breakpoint(small) {
    height: auto;
    position: static;
    width: 100%;
  }

  .c-home & {
    @include breakpoint(medium) {
      margin-bottom: spacing(large);
    }

    @include breakpoint(small) {
      margin-bottom: spacing(medium);
    }
  }
}

.c-sidebar--with-nav {
  @include breakpoint(medium) {
    bottom: 0;
    height: 60px;
    padding-top: 0;
    position: fixed;
    right: 0;
    top: auto;
    transition: height $base-duration $base-timing;
    width: 100%;
  }

  @include breakpoint(small) {
    bottom: 0;
    height: 60px;
    padding-top: 0;
    position: fixed;
    right: 0;
    top: auto;
    transition: height $base-duration $base-timing;
    width: 100%;
  }

  &.is-open {
    @include breakpoint(medium) {
      height: 100%;
      padding-top: 105px;
    }

    @include breakpoint(small) {
      height: 100%;
      padding-top: 75px;
    }
  }
}

.c-sidebar__inner {
  background-color: $purple;
  color: white;
  height: 100%;
  padding: spacing(medium);
  overflow-y: auto;

  @include breakpoint(small) {
    padding: spacing(x-small);
  }

  .c-sidebar--with-nav & {
    @include breakpoint(medium) {
      padding-top: spacing(xx-small);
    }

    @include breakpoint(small) {
      padding-top: spacing(xx-small);
    }
  }
}