figure {
  margin: 0;
}

figcaption {
  color: $light-gray;
  font-size: modular-scale(-2);
  padding-top: spacing(xx-small);
  text-align: center;

  *,
  p {
    font-size: inherit;
  }
}

img,
picture {
  display: block;
  margin: 0;
  max-width: 100%;
}

iframe {
  height: auto;
  width: 100%;
}
