body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $light-font-weight;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  color: $dark-gray;
  font-size: modular-scale(1);
  font-weight: $bold-font-weight;
  line-height: $heading-line-height;
  margin: 0;
}

h1 {
  font-size: modular-scale(10);
  line-height: 1.25;

  @include breakpoint(medium) {
    font-size: modular-scale(9);
  }

  @include breakpoint(small) {
    font-size: modular-scale(7);
  }
}

h2 {
  font-size: modular-scale(5);
  line-height: 1.35;

  @include breakpoint(small) {
    font-size: modular-scale(4);
  }

  .c-course--htma & {
    font-size: modular-scale(6);

    @include breakpoint(small) {
      font-size: modular-scale(4);
    }
  }
}

h3 {
  font-size: modular-scale(4);
  font-weight: $semibold-font-weight;
  line-height: 1.35;

  @include breakpoint(small) {
    font-size: modular-scale(3);
    line-height: 1.25;
  }
}

h4 {
  font-family: $base-font-family-bold;
  font-size: modular-scale(1);
  line-height: 1.45;
}

h5 {
  font-family: $base-font-family-bold;
  font-size: modular-scale(0);
  line-height: 1.45;
}

p {
  font-size: $text-font-size;
  line-height: $text-line-height;
  margin: 0;

  @include breakpoint(small) {
    font-size: $body-font-size;
    line-height: $base-line-height;
  }

  // .c-course--htma & {
  //   font-size: modular-scale(1);
  //   line-height: 1.8;

  //   @include breakpoint(small) {
  //     font-size: $body-font-size;
  //     line-height: $base-line-height;
  //   }
  // }
}

p + p,
ul + p,
ol + p,
h4 + p,
p + h4 {
  display: block;
  margin-top: spacing(small);

  @include breakpoint(small) {
    margin-top: spacing(x-small);
  }
}

blockquote {
  color: $medium-gray;
  font-size: emCal(30px);
  font-style: italic;
  margin: spacing(large);

  @include breakpoint(small) {
    font-size: emCal(22px);
  }

  p {
    font-size: inherit;
  }
}

a {
  color: currentColor;
  position: relative;
  outline: 0;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &.is-active {
    color: $accent-color;
  }

  @media (hover: hover) {
    &:hover {
      color: $accent-color;
    }
  }

  p &,
  li & {
    display: inline; // inline-block;
    border-bottom: solid 1px $accent-color;
    
    // &::after {
    //   background-color: $accent-color;
    //   content: "";
    //   height: 1px;
    //   position: absolute;
    //   left: 0;
    //   bottom: 2px;
    //   width: 100%;
    // }
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

strong,
b {
  font-family: $base-font-family-bold;
  font-weight: normal;
}

i {
  font-style: normal;
}

em {
  font-family: monospace;
  font-weight: inherit;
  font-style: italic;
}

::selection { background-color:#ddd; }

::-webkit-input-placeholder { color: #ebebeb; }
:-moz-placeholder {color: #ebebeb; }
::-moz-placeholder {color: #ebebeb; }
:-ms-input-placeholder {color: #ebebeb; }

.wf-loading {
  opacity: 0;
  transition: opacity $base-duration $base-timing;
}

.wf-active {
  opacity: 1;
  transition: opacity $base-duration $base-timing;
}
