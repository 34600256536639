@function emCal($pixelBasedNum, $emBase: 16px){
  @return $pixelBasedNum / $emBase * 1em;
}

// Breakpoints
$small-screen: 768px;
$small-medium-screen: 900px;
$medium-screen: 1024px;
$large-screen: 1280px;
$xlarge-screen: 1440px;

// Colors
$white: #fff;
$black: #000;
$dark-gray: #111;
$medium-gray: #444; // #333;
$light-gray: #747474; // #999;
$offwhite: #FAFAFA;

$purple: #2B1B53;
$red: #BF4025;

$accent-color: $red;
$color-red: $red;
$color-purple: $purple;

// Font Colors
$base-font-color: $medium-gray;
$secondary-font-color: $dark-gray;

// Typography
$base-font-family: "gt_america_mono_regular", "Courier New", Courier, monospace;
$heading-font-family: "Source Serif Pro", "Times New Roman", Times, serif;

// Font Family
$base-font-family-medium: "gt_america_mono_medium", "Courier New", Courier, monospace;
$base-font-family-bold: "gt_america_mono_bold", "Courier New", Courier, monospace;

// Font Weights
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semibold-font-weight: 600;
$bold-font-weight: 700;

// Font Sizes
$base-font-size:  modular-scale(0);
$small-font-size: modular-scale(-1);
$text-font-size:  modular-scale(1);
$body-font-size: $base-font-size;
$body-font-size-mobile: modular-scale(2);

// Letter Spacing
$base-letter-spacing: 0.125rem;

// Line height
$base-line-height: 1.75;
$text-line-height: 1.8;
$base-line-height-small: 1.5;
$heading-line-height: 1.25;

// Other Sizes
$base-border-radius: 2px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;

// Spacing
$spacing: (
  "xxxx-small": 5px,
  "xxx-small": 10px,
  "xx-small": 20px,
  "x-small":  30px,
  "small":    40px,
  "medium":   50px,
  "large":    60px,
  "x-large":  80px,
  "xx-large":  100px,
  "xxx-large": 150px,
  "xxxx-large": 200px,
);

// Z index
$lowest-z-index: -1;
$base-z-index: 0;
$above-z-index: 1;
$highest-z-index: 999;

// Border
$base-border-color: #dddddd;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: $white;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-input-padding: 15px;
$form-input-height: 50px;
$form-input-min-width: 200px;
$form-input-max-width: 300px;
$form-base-border-color: $base-border-color;
$form-base-border: solid 1px $form-base-border-color;
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($dark-gray, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 350ms;
$base-timing: cubic-bezier(.23, 1, .32, 1);
$base-ease-timing: ease-in-out;

// Small Upcase
$small-upcase-font-size: $small-font-size;
$small-upcase-padding-bottom: 3px;

// Global
$global-body-max-width: 1440px;

$global-side-spacing: 100px;
$global-side-spacing-large: 80px;
$global-side-spacing-medium: 50px;
$global-side-spacing-small: 30px;

$sidebar-width: 370px;
$sidebar-top-spacing: 150px;

$article-text-max-width: 700px;
$article-content-wide-max-width: 880px;

// Share
$share-button-size: 50px;