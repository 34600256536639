.c-page {

}

.c-page--subscribe {
  @include global-side-padding();
  @include padding(spacing(xx-large) null);

  @include breakpoint(small) {
    @include padding(spacing(x-large) null spacing(small));
  }
}

.c-page__body {
  *:nth-child(1) {
    padding-top: 0;
    margin-top: 0;
  }

  h2,
  h3,
  h4,
  h5 {
    @include padding(spacing(small) null);
    // padding-top: spacing(small);

    @include breakpoint(small) {
      @include padding(spacing(x-small) null);
    }

    & + ol {
      margin-top: 0;
    }
  }

  li ol {
    margin-top: 0;
  }

  p {

  }
}