.c-button {
  @extend button;
  @include padding(17px null 15px);
}

.c-button--course-enroll,
.c-button--with-mini-text {
  @include padding(16px null 18px);
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.c-button__text-mini {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  font-size: modular-scale(-2);
  margin-top: 4px;
}

.c-button--disabled {
  background-color: tint($purple, 10%);
  color: rgba(255,255,255,0.35);
  cursor: default;

  &:hover {
    background-color: tint($purple, 10%);
    color: rgba(255,255,255,0.35);
    
    * {
      color: rgba(255,255,255,0.35);
    }
  }

  * {
    color: rgba(255,255,255,0.35);
  }
}