$article-indent: spacing(xx-large);
$article-top-text-spacing: spacing(medium);
$article-top-text-spacing-small: spacing(small);
$article-top-section-spacing: spacing(xx-large);
$article-top-section-spacing-small: spacing(large);

.c-article {
  @include global-side-padding();
  width: 100%;
}

.c-article__body {
  position: relative;
  margin-bottom: spacing(xx-large);

  @include breakpoint(medium) {
    padding-right: $share-button-size + spacing(x-small);
  }

  @include breakpoint(small) {

  }
}

.c-article__heading {
  @include margin($article-top-text-spacing auto null);
  max-width: $article-content-wide-max-width;

  @include breakpoint(medium) {
    @include margin(null 0);
  }

  @include breakpoint(small) {
    @include margin($article-top-text-spacing-small 0 null);
  }

  h2,
  h3,
  h4,
  h5 {
    padding-top: $article-top-text-spacing;

    @include breakpoint(small) {
      padding-top: spacing(x-small);
    }
  }
}

.c-article__heading-inner,
.c-article__text-inner {
  max-width: $article-text-max-width;
}

.c-article__text {
  @include margin($article-top-text-spacing auto null);
  max-width: $article-content-wide-max-width;

  @include breakpoint(small) {
    @include margin($article-top-text-spacing-small null null);
  }

  h3,
  h4,
  h5 {
    @include margin($article-top-text-spacing null);

    @include breakpoint(small) {
      @include margin(spacing(small) null);
    }
  }
}

.c-article__blockquote {
  @include margin($article-top-text-spacing auto null);
  max-width: $article-content-wide-max-width;;
  border-left: solid 5px $accent-color;

  @include breakpoint(medium) {
    @include margin(null 0);
  }

  @include breakpoint(small) {
    @include margin($article-top-text-spacing-small 0 null);
  }

  blockquote {
    color: $light-gray;
    font-size: modular-scale(0);
    font-style: normal;
    margin-left: spacing(medium);
  }
}

.c-article__blockquote-inner {
  max-width: $article-text-max-width;
}

.c-article__image {
  @include margin($article-top-text-spacing auto null);

  @include breakpoint(small) {
    @include margin($article-top-text-spacing-small null null);
  }
}

.c-article__image--small {
  max-width: $article-content-wide-max-width;

  figure {
    max-width: $article-text-max-width;
  }
}

.c-article__image--medium {
  max-width: $article-content-wide-max-width;
}

.c-article__image--main {
  margin-top: 0;
  padding-bottom: spacing(medium);

  @include breakpoint(small) {
    padding-bottom: spacing(x-small);
  }
}


.c-article__share {
  position: absolute;
  bottom: 0;
  right: 0; 
  width: 100%;
  pointer-events: none;

  @include breakpoint(small) {
    margin-top: spacing(medium);
    position: static;
  }

  .c-share {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 880px;
    width: 100%;

    @include breakpoint(small) {
      flex-direction: row;
    }
  }

  .c-share__button {
    pointer-events: all;
  }

  .c-share__button + .c-share__button {
    margin-top: spacing(xx-small);

    @include breakpoint(small) {
      margin-left: spacing(xxx-small);
      margin-top: 0;
    }
  }
}

// .c-article__share {
//   @include margin($article-top-section-spacing auto);
//   text-align: center;

//   @include breakpoint(small) {
//     @include margin($article-top-section-spacing-small null null);
//   }
// }

.c-article__share-heading {
  display: none;
  margin-bottom: spacing(xx-small);

  @include breakpoint(small) {
    display: block;
  }
}

.c-article__footer {
  @include global-side-padding();
  // background-color: #fbfbfb;

  @include breakpoint(large) {
    background-color: #fbfbfb;
  }

  @include breakpoint(medium) {
    background-color: #fbfbfb;
  }

  @include breakpoint(small) {
    background-color: #fbfbfb;
  }
}

.c-article__footer-inner {
  @include padding(spacing(xx-large) null);
  background-color: #fbfbfb;

  @include breakpoint(medium) {
    @include padding(spacing(large) null);
  }

  @include breakpoint(small) {
    @include padding(spacing(small) null);
  }
}

.c-article__footer-content {
  @include margin(null auto);
  max-width: $article-content-wide-max-width;
}

.c-article__related {

}

.c-article__email-capture {
  background-color: $purple;
  color: white;
  padding: spacing(medium);

  @include breakpoint(small) {
    margin-top: spacing(x-small);
    padding: spacing(x-small);
  }
}