.c-form {
	width: 100%;
}

.c-form__field-group {
	display: flex;
}

.c-form__field-group + .c-form__field-group {
	margin-top: spacing(x-small);
}

.c-form__field {
	width: 100%;
}

.c-form__field + .c-form__field {
	margin-left: spacing(x-small);
}

.c-form__button--full-width {
	width: 100%;
}

.c-form__button-group {
	margin-top: spacing(x-small);
}

.c-form__response {
	display: none;
	margin-top: spacing(small);

	.c-form__response--error {
		color: red;
	}

	.c-form__response--success {
		color: $color-red;
	}
}

.c-form__error {
	color: red;
	display: block;
	font-size: modular-scale(-2);
	margin-top: spacing(xxxx-small);
}

.c-form__field-label {
  .u-color--white & {
    color: $white;
  }
}