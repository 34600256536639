$intro-phase-width: 100px;
$intro-phase-height: 90px;
$intro-phase-width-small: 180px;
$intro-phase-height-small: 50px;

.c-intro {
  @include global-side-padding();
  @include padding(spacing(xxx-large) null spacing(xx-large));
  
  @include breakpoint(small) {
    @include padding(spacing(x-large) null spacing(medium));
  }
  
  .u-layout--sidebar & {
    @media all and (min-width: $medium-screen) {
      padding-right: 0;
    }
  }

  .c-guide & {
    @include padding(null 0);
  }

  .c-course & {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(small) {
      display: block;
      @include padding(null 0);
    }
  }
}

.c-intro__heading--sub {
  @include small-text-uppercase();
  color: $light-gray;
  display: block;
  font-size: 14px;
  padding-bottom: spacing(xx-small);
}

.c-intro__subheading {
  font-size: modular-scale(1);
  margin-top: spacing(xx-small);

  .c-course & {
    font-size: modular-scale(3);

    @include breakpoint(small) {
      font-size: modular-scale(1);
    }
  }
}

.c-intro__content {
  .c-intro--article & {
    @include margin(null auto);
    max-width: $article-content-wide-max-width;
    padding-right: spacing(xxx-large);
    position: relative;

    @include breakpoint(small) {
      padding-right: 0;
    }
  }
}

.c-intro__button {
  margin-bottom: spacing(xxx-small);
  margin-left: spacing(xx-large);

  @include breakpoint(medium) {
    margin-left: 0;
    margin-top: spacing(small);
  }

  @include breakpoint(small) {
    margin-left: 0;
    margin-top: spacing(x-small);
  }
}

.c-intro__phase {
  @include padding(spacing(xxx-small));
  border: $base-border;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $intro-phase-height;
  text-align: center;
  position: absolute;
  top: spacing(xx-small);
  right: 0;
  width: $intro-phase-width;

  @include breakpoint(small) {
    @include padding(spacing(xx-small));
    align-items: center;
    flex-direction: row;
    margin-top: spacing(x-small);
    position: static;
    height: $intro-phase-height-small;
    width: $intro-phase-width-small;
  }
}

.c-intro__phase-heading {
  @include small-text-uppercase();
  color: $light-gray;
}

.c-intro__phase-text {
  font-family: $heading-font-family;
  font-weight: $bold-font-weight;
}