$article-preview-image-tall-height: 200px;
$article-preview-image-short-width: 150px;

.c-article-preview {
  display: flex;
  height: 100%;
}

.c-article-preview__image {
  background-color: #eee;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .c-article-preview--tall & {
    // padding-bottom: 75%;
    height: $article-preview-image-tall-height;
    width: 100%;
  }

  .c-article-preview--short & {
    width: $article-preview-image-short-width;
    // height: 100%;
  }

  .c-article-preview--draft &,
  .c-article-preview--pending & {
    opacity: 0.2;
  }
}

.c-article-preview__content {
  width: 100%;
  
  .c-article-preview--tall & {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: spacing(xx-small);
  }

  .c-article-preview--short & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: spacing(x-small);
  }
}

.c-article-preview__heading {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: modular-scale(1);
  font-weight: $regular-font-weight;
  line-height: $base-line-height;

  @include breakpoint(small) {
    font-size: modular-scale(0);
    line-height: $base-line-height-small;
  }

  a:hover &,
  a:active & {
    color: $accent-color;
  }

  .c-article-preview--draft &,
  .c-article-preview--pending & {
    color: #bbb;
  }
}

.c-article-preview__meta {
  @include small-text-uppercase();
  color: $light-gray;
  display: block;
  margin-top: spacing(xx-small);

  .c-article-preview--draft &,
  .c-article-preview--pending & {
    color: #bbb;
  }
}

.c-article-preview--tall {
  flex-direction: column;
  justify-content: space-between;
}