.c-terms,
.c-tools {
  @include global-side-padding();
  
  .u-layout--sidebar & {
    @media all and (min-width: $medium-screen) {
      padding-right: 0;
    }
  }
}

.c-terms__content,
.c-tools__content {
  @include padding(null null spacing(medium));

  @include breakpoint(medium) {
    @include padding(null null spacing(xx-large));
  }

  @include breakpoint(small) {
    @include padding(null null 0);
  }
}

.c-terms__items,
.c-tools__items {
  @include breakpoint(medium) {
    margin-bottom: spacing(small);
  }

  @include breakpoint(small) {
    margin-bottom: spacing(small);
  }
}