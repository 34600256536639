.c-banner {
  @include global-side-padding();
  @include padding(spacing(xxx-small) null);
  background-color: $accent-color;
  color: $white;
  display: block;
  font-size: modular-scale(-1);
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: $highest-z-index;

  &:active,
  &:focus {
    color: $white;
    background-color: shade($accent-color, 10%);
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
      background-color: shade($accent-color, 10%);
    }
  }

  @include breakpoint(small) {
    position: static;
  }
}