$header-logo: 200px;
$header-logo-small: 175px;

.c-header {
  @include global-side-padding();
  @include padding(spacing(medium) null spacing(xx-small));
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $highest-z-index;

  @include breakpoint(small) {
    @include padding(0 null);
  }

  @include breakpoint(xx-large) {
    max-width: $global-body-max-width;
    left: 50%;
    transform: translateX(-50%);
  }
}

.c-header--white {
  @media all and (min-width: $small-screen) {
    background-color: transparent;
  }
}

.c-header--banner-active {
  margin-top: spacing(x-small);

  @include breakpoint(small) {
    margin-top: 0;
  }
}

.c-header__logo {
  display: block;
  margin-top: 5px;
  position: relative;
  width: $header-logo;
  z-index: 1;

  @include breakpoint(medium) {
    width: $header-logo-small;
  }

  @include breakpoint(small) {
    margin-top: 2px;
    width: $header-logo-small;
  }

  &::after {
    border: 0;
    content: none;
  }
  svg path {
    .c-header--white & {
      @media all and (min-width: $small-screen) {
        fill: white;
      }
    }
  }
}

.c-header__main {
  @include breakpoint(small) {
    @include padding($global-side-spacing-small null spacing(xxx-small));
    background-color: $white;
    z-index: 1;
  }
}