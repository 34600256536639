.u-hidden {
  display: none;
}

.u-hidden\@small {
  @include breakpoint(small) {
    display: none;
  }
}

.u-hidden\@medium {
  @include breakpoint(medium) {
    display: none;
  }
}

.u-hidden\@large {
  @include breakpoint(large) {
    display: none;
  }
}

.u-hidden\@x-large {
  @include breakpoint(x-large) {
    display: none;
  }
}