.u-grid {
  display: grid;
  grid-column-gap: spacing(medium);
  grid-row-gap: spacing(medium);
  grid-template-columns: repeat(12, 1fr);

  @include breakpoint(large) {
    grid-column-gap: spacing(small);
    grid-row-gap: spacing(small);
  }

  @include breakpoint(medium) {
    grid-column-gap: spacing(x-small);
    grid-row-gap: spacing(small);
  }

  @include breakpoint(small) {
    grid-column-gap: spacing(xx-small);
    grid-row-gap: spacing(small);
  }
}


@for $i from 1 through 12 {
  .u-grid--#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

@for $i from 1 through 12 {
  .u-grid__column--#{$i} {
    grid-column-end: span $i;
  }
}

@include breakpoint(xx-large) {
  @for $i from 1 through 12 {
    .u-grid__column--#{$i}\@xxlarge {
      grid-column-end: span $i;
    }
  }
}

@include breakpoint(large) {
  @for $i from 1 through 12 {
    .u-grid__column--#{$i}\@large {
      grid-column-end: span $i;
    }
  }
}

@include breakpoint(medium) {
  @for $i from 1 through 12 {
    .u-grid__column--#{$i}\@medium {
      grid-column-end: span $i;
    }
  }
}

@include breakpoint(small) {
  @for $i from 1 through 12 {
    .u-grid__column--#{$i}\@small {
      grid-column-end: span $i;
    }
  }
}