.c-accordian {
  border: $base-border;
  margin-top: -1px;

  &.is-active {
    background-color: $offwhite;
  }
}

.c-accordian__header {
  cursor: pointer;
  padding: 15px spacing(large) 15px spacing(x-small);
  position: relative;

  &:hover {
    background-color: $offwhite;
  }

  h5 {
    font-family: $base-font-family-medium;
    font-weight: normal;
  }

	@include breakpoint(small) {
    @include padding(null spacing(xx-small));
  }
}

.c-accordian__header--list {
  background-color: white;
}

.c-accordian__body {
  font-size: modular-scale(-1);
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height $base-duration $base-timing;
  

  p {
    @include padding(spacing(xxx-small) null spacing(x-small));
    font-size: inherit;

    @include breakpoint(small) {
      padding-bottom: spacing(xx-small);
    }
  }

  .c-accordian.is-active & {
    height: auto;
  }
}

.c-accordian__body--simple {
  @include padding(null spacing(x-small));

  @include breakpoint(small) {
    @include padding(null spacing(xx-small));
  }
}

.c-accordian__body--list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-top: $base-border;
    padding: 15px spacing(large) 15px spacing(x-small);
  }
}

.c-accordian__arrow {
  color: $accent-color;
  display: block;
  right: spacing(xx-small);
  position: absolute;
  top: spacing(xx-small);
  transition: transform $base-duration $base-timing;
  transform: rotate(90deg);

  .c-accordian.is-active & {
    transform: rotate(-90deg);
  }
}