.c-term,
.c-tool {
  @include global-side-padding();
  @include padding(spacing(xxx-large) null spacing(xx-large));

  @include breakpoint(medium) {
    @include padding(spacing(xx-large) null 0);
  }

  @include breakpoint(small) {
    @include padding(spacing(x-large) null 0);
  }
}

.c-term__header,
.c-tool__header {
  margin-bottom: spacing(medium);
}

.c-tool__categories {
  margin-top: spacing(xxx-small);
}

.c-tool__category {
  @include small-text-uppercase();
  display: inline-block;
}

.c-tool__category + .c-tool__category {
  &::before {
    color: #aaa;
    content: "|";
    // margin-left: spacing(xxx-small);
    padding-right: spacing(xxx-small);
  }
}

.c-term__description,
.c-tool__description,
.c-tool__details {
  h3,
  h4,
  h5 {
    @include margin(spacing(medium) null spacing(x-small));

    @include breakpoint(small) {
      @include margin(spacing(small) null spacing(xx-small));
    }
  }

  h3 + ul,
  p + ul {
    @include breakpoint(small) {
      margin-top: spacing(xx-small);
    }
  }
}

.c-tool__details {
  // font-size: modular-scale(1);

  @include breakpoint(medium) {
    margin-bottom: spacing(large);
  }

  @include breakpoint(small) {
    margin-bottom: spacing(medium);
  }
}


.c-tool__website {
  @include margin(spacing(xx-small) null spacing(medium));
  font-size: modular-scale(1);

  @include breakpoint(small) {
    font-size: modular-scale(0);
  }
}

.c-term__related,
.c-tool__related {
  margin-top: spacing(xx-large);

  @include breakpoint(medium) {
    @include margin(spacing(x-large) null spacing(large));
  }

  @include breakpoint(small) {
    @include margin(spacing(large) null spacing(medium));
  }
}

.c-tool__screenshot {
  border: $base-border;
  margin-bottom: spacing(medium);
}