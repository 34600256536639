.c-latest {
  @include global-side-padding();
  
  .u-layout--sidebar & {
    @media all and (min-width: $medium-screen) {
      padding-right: 0;
    }
  }
}

.c-latest__heading {
  margin-bottom: spacing(medium);
}

.c-latest__content--home {
  margin-bottom: spacing(xx-large);

  @include breakpoint(medium) {
    margin-bottom: spacing(large);
  }

  @include breakpoint(small) {
    margin-bottom: spacing(medium);
  }
}

.c-latest__link {
  display: block;
  margin-top: spacing(small);
}