.c-email-capture--large {
  @include global-side-padding();
}

.c-email-capture--home,
.c-email-capture--course {
  margin-top: spacing(medium);

  .c-form,
  .c-form__fields,
  .c-form__field-group,
  button {
    width: 100%;
  }

  .c-form__fields {
    @media all and (min-width: $small-screen) {
      align-items: flex-end;
      display: flex;
    }
  }

  .c-form__field-group:nth-child(1) {
    @media all and (min-width: $small-screen) {
      margin-right: spacing(xx-small);
    }
  }

  .c-form__field-group:nth-child(2) {
    @media all and (min-width: $small-screen) {
      margin-left: spacing(xx-small);
      margin-top: 0;
    }
  }
}

.c-email-capture--course {
  .c-form__content {
    @media all and (min-width: $small-medium-screen) {
      display: flex;
      align-items: flex-end;
    }
  }

  .c-form__fields {
    @media all and (min-width: $small-medium-screen) {
      width: 70%;
      margin-right: spacing(small);
    }
  }

  .c-form__button-group {
    @media all and (min-width: $small-medium-screen) {
      width: 30%;
    }
  }
}


.c-email-capture--articles {
  margin-top: spacing(xx-large);

  @include breakpoint(medium) {
    margin-top: spacing(large);
  }

  @include breakpoint(small) {
    margin-top: spacing(medium);
  }
}

.c-email-capture__content {
  .c-email-capture--large &,
  .c-email-capture--purple & {
    background-color: $purple;
    color: $white;
    padding: spacing(xx-large) spacing(xxx-large);

    @include breakpoint(medium) {
      padding: spacing(x-large) spacing(medium);
    }

    @include breakpoint(small) {
      padding: spacing(x-small) spacing(x-small);
    }
  }
}

.c-email-capture__image,
.c-email-capture__form {
  align-items: center;
  display: flex;
}

.c-email-capture__image,
.c-email-capture__spacer {
  @include breakpoint(small) {
    display: none;
  }
}

.c-email-capture__image {
  .c-guide &,
  .c-subscribe & {
    @include breakpoint(small) {
      display: block;
    }
  }
}

.c-email-capture__header {
  margin-bottom: spacing(medium);
  
  @include breakpoint(small) {
    margin-bottom: spacing(x-small);
  }
}

.c-email-capture__title {
  margin-bottom: spacing(xxx-small);

  .c-guide &,
  .c-subscribe & {
    font-size: modular-scale(5);
    line-height: 1.35;

    @include breakpoint(small) {
      font-size: modular-scale(4);
    }
  }
}

.c-email-capture__title,
.c-email-capture__description {
  .u-color--white & {
    color: $white;
  }
}

.c-email-capture__meta {
  @include small-text-uppercase();
  color: $light-gray;
  display: block;
  margin-bottom: spacing(x-small);
}
