.c-link__text {
  vertical-align: middle;
}

.c-link__icon {
  vertical-align: middle;

  &.icon-arrow {
    font-size: 15px;

    .c-link--back & {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}