.c-sidebar-nav__header {
  margin-bottom: spacing(small);

  @include breakpoint(medium) {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  @include breakpoint(small) {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
}

.c-sidebar-nav__heading {
  @include small-text-uppercase();
  color: $white;
  display: inline-block;
  font-size: modular-scale(-1);

  @include breakpoint(small) {
    font-size: modular-scale(-2);
  }
}

.c-sidebar-nav__heading-icon {
  display: none;
  font-size: 14px;
  transform: rotate(-90deg);

  @include breakpoint(medium) {
    display: inline-block;
  }

  @include breakpoint(small) {
    display: inline-block;
  }

  .c-sidebar.is-open & {
    transform: rotate(90deg);
  }
}

.c-sidebar-nav__category {
  @include small-text-uppercase();
  color: rgba(255, 255, 255, 0.5);
  font-size: modular-scale(-2);
  margin-top: spacing(small);
}

.c-sidebar-nav__list {
  @include margin(spacing(xxx-small) null null 0);
  list-style: none;
}

.c-sidebar-nav__item {
  color: $white;
  font-size: modular-scale(-1);
}

.c-sidebar-nav__item + .c-sidebar-nav__item {
  margin-top: spacing(xxxx-small);
}

.c-sidebar-nav__link {
  &::after {
    background-color: transparent;
    content: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, 0.6);
  }
}