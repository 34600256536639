ul,
ol {
  font-size: $text-font-size;
  line-height: $text-line-height;
  padding: 0;
  list-style-position: outside;

  @include breakpoint(small) {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

ul {
  margin: spacing(small) 0 0 spacing(xx-small);

  .c-article &,
  .c-tool & {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    position: relative;
  }
}

ol {
  margin: spacing(small) 0 0 spacing(x-small);

  .c-article &,
  .c-tool & {
    counter-reset: ol-list-counter;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    position: relative;
  }
}

li {

  .c-article ul &,
  .c-tool ul & {
    padding-left: spacing(xx-small);
    position: relative;

    &::before {
      @include size(10px);
      content: "";
      display: inline-block;
      border: solid 2px $red;
      border-radius: 100%;
      margin-right: 15px;
      position: absolute;
      top: 11px;
      left: 0;

      @include breakpoint(small) {
        @include size(8px);
        top: 10px;
      }
    }
  }

  .c-article ul &::before {
    border-color: currentColor;
  }

  .c-article ol &,
  .c-tool ol & {
    counter-increment: ol-list-counter;
    padding-left: spacing(x-small);
    position: relative;

    &::before {
      @include size(10px);
      color: $red;
      content: counter(ol-list-counter) ".";
      font-weight: bold;
      display: inline-block;
      margin-right: 15px; 
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .c-article ol &::before {
    color: currentColor;
    font-weight: inherit;
  }
}

li + li {
  .c-article &,
  .c-tool & {
    margin-top: spacing(xxx-small);

    @include breakpoint(small) {
      margin-top: spacing(xxxx-small);
    }
  }
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}
