$course-section-max-width: 700px;
$course-benefit-height: 270px;
$course-benefit-height-small: 250px;

.c-course {
  @include global-side-padding();
  padding-bottom: spacing(large);

  @include breakpoint(small) {
    padding-bottom: 0;
  }
}

.c-course__trailer {
  padding-bottom: 56.25%;
  position: relative;
}

.c-course__trailer-play-wrapper {
  @include size(100%);
  align-items: center;
  background-color:$accent-color;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10;
}

.c-course__trailer-play-button {
  width: 0; 
  height: 0; 
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 40px solid white;
}

.c-course__trailer-embed-wrapper {
  @include size(100%);
  position: absolute;
}

.c-course__trailer-embed {
  @include size(100%);
  position: absolute;
  top: 0;
  left: 0;
}

.c-course__section {
  @include global-side-padding();
  @include padding(spacing(xx-large) null null);

	@include breakpoint(medium) {
		@include padding(spacing(large) null null);
  }

	@include breakpoint(small) {
		@include padding(spacing(medium) 0 null);
  }
  
  &.c-course__section--instructor,
  &.c-course__section--pricing,
  &.c-course__section--footer {
    @include padding(null 0);
  }

  &.c-course__section--curriculum {
    .c-course--curriculum & {
      padding-top: spacing(small);
    }
  }
}

.c-course__section-header {
  padding-bottom: spacing(medium);

  .c-course__section--pricing & {
    @include padding(null spacing(xx-large));
    width: 100%;

    @include breakpoint(medium) {
      @include padding(null $global-side-spacing-medium);
    }

    @include breakpoint(small) {
      @include padding(null 0);
    }
  }

  .c-course__section-heading--center {
    text-align: center;
  }
}

.c-course__section-description {
  padding-top: spacing(xx-small);

  p {
    font-size: modular-scale(1);

    @include breakpoint(small) {
      font-size: modular-scale(0);
    }
  }
}

.c-course__description {
  max-width: $course-section-max-width;
}

.c-course__benefit {
  @include padding(spacing(x-small));
  border: $base-border;
  border-radius: $base-border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: $course-benefit-height;
  height: 100%;
  width: 100%;

  @include breakpoint(small) {
    min-height: $course-benefit-height-small;
  }
}

.c-course__benefit-icon {
  @include size(30px);
  color: $accent-color;
  display: block;
  font-size: 30px;
  margin-bottom: spacing(xx-small);

  &.icon-online {
    font-size: 26px;
  }
}

.c-course__benefit-name {
  @include margin(7px null spacing(xxx-small));
  font-size: modular-scale(3);
  font-family: $base-font-family-medium;
  font-size: $base-font-size;
  font-weight: normal;
  line-height: 1.25;
}

.c-course__benefit-description {
  font-size: modular-scale(-1);
  line-height: $base-line-height;
}

.c-course__info-list {
  list-style: none;
  margin: 0;
  max-width: $course-section-max-width;
}

.c-course__info-list-item {
  padding-left: 50px;
  position: relative;

  @include breakpoint(small) {
    padding-left: 40px;
  }
}

.c-course__info-list-item + .c-course__info-list-item {
  margin-top: spacing(x-small);
}

.c-course__info-list-item::before {
  @extend [class^="icon-"];
  color: $accent-color;
  content: "\e90f";
  font-size: 25px;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;

  @include breakpoint(small) {
    font-size: 23px;
    top: 2px;
  }
}

.c-course__instructor-header {
  align-items: center;
  display: flex;

	@include breakpoint(small) {
    display: block;
  }
}

.c-course__instructor-photo {
  max-width: 45%;

	@include breakpoint(small) {
    max-width: 100%;
  }
}

.c-course__instructor-heading {
  padding-left: spacing(xx-large);

	@include breakpoint(small) {
    padding-left: 0;
    padding-top: spacing(medium);
  }
}

.c-course__instructor-body {
  @include padding(null spacing(xx-large));
  width: 100%;

	@include breakpoint(medium) {
		@include padding(null $global-side-spacing-medium);
  }

	@include breakpoint(small) {
		@include padding(null 0);
  }
}

.c-course__instructor-bio {
  max-width: $course-section-max-width;
  padding-top: spacing(xx-large);

	@include breakpoint(small) {
    padding-top: spacing(medium);
  }
}

.c-course__pricing {
  background-color: $purple;
  color: $white;
  padding: spacing(xx-large);

	@include breakpoint(medium) {
    padding: spacing(large);
  }

	@include breakpoint(small) {
    padding: spacing(small) spacing(x-small);
  }
}

.c-course__price {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.c-course__price-name {
  @include margin(null auto);
  color: $white;
  font-weight: $bold-font-weight;
  padding-bottom: spacing(x-small);
  max-width: 575px;
  text-align: center;

  @include breakpoint(small) {
    @include margin(null 0);
    text-align: left;;
  }
}

.c-course__price-name--plan {
  font-size: modular-scale(2);
  font-weight: $semibold-font-weight;
  margin-top: spacing(xxxx-small);
}

.c-course__price-description,
.c-course__price-details {
  font-size: modular-scale(-1);

  // .c-course--curriculum & {
  //   font-size: modular-scale(0);
  // }
}

.c-course__price-details {
  @include padding(spacing(x-small) null);

  @include breakpoint(small) {
    padding-top: 0;
  }

  ul {
    column-count: 2;
    font-size: modular-scale(1);
    list-style: none;
    margin: 0;

    @include breakpoint(medium) {
      font-size: modular-scale(0);
    }
    
    @include breakpoint(small) {
      column-count: 1;
      font-size: modular-scale(0);
    }
  }

  ul li {
    font-size: inherit;
  }

  ul li::before {
    content: "";
    border-bottom: solid 2px white;
    border-right: solid 2px white;
    display: inline-block;
    height: 15px;
    margin-right: spacing(xx-small);
    width: 7px;
    transform: rotate(45deg);
  }

  li {
    padding-left: spacing(xx-small);

    @include breakpoint(small) {
      padding-left: 0
    }
  }
}

.c-course__price-payment-plan {
  margin-top: spacing(x-small);
  text-align: center;

  @include breakpoint(small) {
    margin-top: 0;
    text-align: left;
  }
}

.c-course__pricing-info {
  @include margin(null auto);
  max-width: 700px;
}

.c-course__pricing-meta {
  font-size: modular-scale(0);
  margin-top: spacing(x-small);
  text-align: center;

  p {
    font-size: inherit;
  }
}

.c-course__pricing-guarantee {
  background-color: #f3f3f3;
  text-align: center;
  padding: spacing(small) spacing(xx-large) spacing(medium);

	@include breakpoint(medium) {
    padding: spacing(small) spacing(large);
  }

	@include breakpoint(small) {
    padding: spacing(x-small) spacing(small);
  }

  p {
    font-size: modular-scale(-1);
    margin: spacing(xxx-small) auto 0;
    max-width: $course-section-max-width;
  }
}

.c-course__review-heading {
  border-top: solid 5px $accent-color;
  margin-right: spacing(large);
  padding-top: spacing(x-small);

	@include breakpoint(small) {
    margin-right: 0;
  }
}

.c-course__review-quote {
  font-size: modular-scale(-1);
  font-style: normal;
  margin: 0;
}

.c-course__review-name {
  color: $dark-gray;
  display: block;
  font-size: modular-scale(-1);
  margin-top: spacing(xxx-small);
}

.c-course__footer {
  @include global-side-padding();
  @include padding(spacing(x-large) null);
  align-items: center;
  background-color: $purple;
  display: flex;

	@include breakpoint(medium) {
		@include padding(spacing(medium) null);
  }

	@include breakpoint(small) {
		@include padding(spacing(small));
    display: block;
  }
}

.c-course__footer-heading {
  color: $white;
  padding-right: spacing(xx-large);

	@include breakpoint(small) {
    padding-bottom: spacing(x-small);
    padding-right: 0;
  }
}

.c-course__footer-links {
  @include global-side-padding();
  @include padding(spacing(xx-large) null null);
  display: flex;
  justify-content: space-between;

	@include breakpoint(medium) {
		@include padding(spacing(large) null null);
  }

	@include breakpoint(small) {
    @include padding(spacing(medium) 0 null);
    display: block;
  }
}

.c-course__footer-links-section + .c-course__footer-links-section {
	@include breakpoint(small) {
    margin-top: spacing(xx-small);
  }
}

.c-course__footer-link {
  display: inline-block;

  &:hover &::before,
  &:active &::before,
  &.is-active &::before {
    color: #bbb;
  }
}

.c-course__footer-link + .c-course__footer-link {
  &::before {
    color: #bbb;
    content: " | ";
  }
}

.c-course__curriculum-package {
  @include small-text-uppercase();
  color: $accent-color;
}

/* HOW TO MAKE AN APP STYLES */
$course-section-max-width: 1280px;
$course-section-header-max-width: 800px;
$course-benefit-height: 300px;
$course-benefit-height-small: 250px;

.c-course--htma {
  // @include padding(calc(100vh - 100px) 0 null);
  @include padding(null 0);

  @include breakpoint(small) {
    padding-top: 0;
  }

  .c-course__intro {
    display: flex;
    position: relative;

    @include breakpoint(small) {
      display: block;
    }
  }

  .c-course__intro-left,
  .c-course__intro-right {
    padding: spacing(x-large);
    width: 50%;

    @include breakpoint(medium) {
      padding: spacing(medium);
    }

    @include breakpoint(small) {
      padding: spacing(x-small);
      width: 100%;
    }
  }

  .c-course__intro-left {
    background-color: $purple;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media all and (min-width: $small-screen) {
      bottom:0;
      left: 0;
      height: 100%;
      position: absolute;
    }

    @include breakpoint(x-large) {
      padding-left: spacing(xx-large);
      padding-right: spacing(x-large);
    }

    @include breakpoint(small) {
      display: block;
      padding-top: spacing(xxxx-large);
    }

    .site-htma--home.has-bg & {
      @media all and (min-width: $small-screen) {
        position: fixed;
      }
    }
  }

  .c-course__intro-right {
    padding-top: spacing(xxx-large);
    width: 100%;
    
    @media all and (min-width: $small-screen) {
      padding-left: calc(50% + #{spacing(medium)});
    }

    @media all and (min-width: $medium-screen) {
      padding-left: calc(50% + #{spacing(x-large)});
    }

    @include breakpoint(x-large) {
      padding-right: spacing(xx-large);
    }

    @include breakpoint(small) {
      padding-top: spacing(small);
      padding-bottom: spacing(small);
    }
  }

  .c-course__intro-heading {
    color: white;
    font-size: modular-scale(12);
    font-weight: $semibold-font-weight;

    @include breakpoint(small) {
      font-size: modular-scale(9);
    }
  }

  .c-course__intro-subheading {
    color: rgba(255,255,255,0.85);
    font-size: modular-scale(2);
    margin-top: spacing(xx-small);

    @include breakpoint(small) {
      font-size: modular-scale(1);
    }
  }
  .c-course__intro-button {
    margin-top: spacing(medium);
    max-width: $form-input-max-width;

    @include breakpoint(small) {
      margin-top: spacing(small);
      max-width: 100%;
    }
  }

  .c-course__benefit {
    background-color: white;
    min-height: $course-benefit-height;

    @include breakpoint(small) {
      min-height: $course-benefit-height-small;
    }
  }

  .c-course__benefit-name {
    font-size: modular-scale(1);

    @include breakpoint(small) {
      font-size: modular-scale(0);
    }
  }

  .c-course__benefit-description {
    font-size: modular-scale(0);
    line-height: 1.65;

    @include breakpoint(small) {
      font-size: modular-scale(-1);
    }
  }

  .c-course__section {
    padding-top: 130px;
    position: relative;

    @include breakpoint(medium) {
      padding-top: spacing(xx-large);
    }

    @include breakpoint(small) {
      @include global-side-padding();
      padding-top: spacing(x-large);
    }

    &.c-course__section--blueprint,
    &.c-course__section--reviews,
    &.c-course__section--instructor {
      padding-bottom: 130px;

      @include breakpoint(medium) {
        padding-bottom: spacing(xx-large);
      }

      @include breakpoint(small) {
        padding-bottom: spacing(x-large);
      }
    }

    &.c-course__section--instructor,
    &.c-course__section--pricing,
    &.c-course__section--footer {
      @include global-side-padding();
    }

    &.c-course__section--pricing {
      @include breakpoint(small) {
        @include padding(null 0);
      }
    }
  }

  
  .c-course__section--objectives,
  .c-course__section--benefits,
  .c-course__section--instructor,
  .c-course__section--email-capture {
    .c-course__section-header {
      @include margin(null auto);
      max-width: $course-section-header-max-width;
    }
  }

  .c-course__section--email-capture {
    .c-course__section-header {
      padding-bottom: 0;
    }
  }

  .c-course__section-inner {
    @include global-side-padding();
    @include margin(null auto);

    @include breakpoint(small) {
      @include padding(null 0);
    }
  }

  .gray-header {
    background-color: $offwhite;
    position: absolute;
    height: 350px;;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .c-course__review + .c-course__review {
    margin-top: spacing(medium);
  }

  .c-course__review-quote,
  .c-course__review-name {
    font-size: modular-scale(1);

    @include breakpoint(small) {
      font-size: modular-scale(0);
    }

    p {
      font-size: inherit;
    }
  }

  .c-course__section--instructor {
    background-color: $offwhite;
  }

  .c-course__instructor-photo {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;

    @include breakpoint(small) {
      max-width: 200px;
    }

    img {
      border-radius: 100%;
      margin-top: spacing(xx-small);
    }

    h5 {
      margin-top: spacing(x-small);
    }
  }

  .c-course__instructor-body {
    @include padding(spacing(x-small) 0 0);

    @include breakpoint(small) {
      padding-top: 0;
    }
  }

  .c-course__instructor-bio {
    padding-top: 0;

    @include breakpoint(medium) {
      padding-top: spacing(small);
    }

    @include breakpoint(small) {
      padding-top: 0;
    }
  }

  .c-course__instructor-quote {
    @include margin(spacing(large) auto null);
    font-family: $heading-font-family;
    font-weight: $semibold-font-weight;
    max-width: $course-section-header-max-width;

    @include breakpoint(small) {
      @include margin(spacing(small) 0 null);
    }

    p {
      font-size: modular-scale(4);
      line-height: 1.6;
      text-align: center;

      @include breakpoint(small) {
        font-size: modular-scale(2);
        text-align: left;
      }
    }
  }
  
  .c-course__section--results {
    .c-course__section-inner {
      // @include padding(null spacing(medium));

      // @include breakpoint(small) {
      //   @include padding(null $global-side-spacing-small);
      // }
    }
  }

  .c-course__info-list {
    margin-top: -115px;
    max-width: 100%;

    @include breakpoint(medium) {
      margin-top: 0;
    }

    @include breakpoint(small) {
      margin-top: 0;
    }

    &::before {
      content: "";
      @extend .u-grid__column--6;

      @include breakpoint(medium) {
        display: none;
        content: none;
      }
      @include breakpoint(small) {
        display: none;
        content: none;
      }
    }
  }

  .c-course__info-list-item + .c-course__info-list-item {
    margin-top: 0;
  }

  .c-course__section--faqs-intro {
    .c-course__section-header {
      border-left: solid 5px $red;
      padding-bottom: 0;
      padding-left: spacing(medium);

      @include breakpoint(small) {
        padding-left: spacing(x-small);
      }
    }

    .c-course__section-description {
      padding-top: spacing(small);

      @include breakpoint(small) {
        padding-top: spacing(x-small);
      }
    }
  }

  .c-course__section--email-capture {
    @include margin(null auto);
    max-width: $course-section-max-width;
  }
}


.c-course__blueprint-header {
  padding-right: spacing(xxx-large);
  width: 50%;

  @include breakpoint(medium) {
    padding-right: spacing(xx-large);
  }

  @include breakpoint(small) {
    padding-right: 0;
    width: 100%;
  }

  h2 {
    margin-left: auto;
    max-width: 320px;

    @include breakpoint(small) {
      @include margin (null null spacing(x-large) 0);
      max-width: 100%;
    }
  }
}

.c-course__blueprint-items {
  list-style: none;
  margin-left: 0;
  padding-left: 50%;
  position: relative;
  width: 100%;

  @include breakpoint(small) {
    padding-left: spacing(xx-small);
    width: 100%; 
  }
}

.c-course__blueprint-item {
  counter-increment: ol-list-counter;
  max-width: 550px;
  padding-left: spacing(x-large);
  position: relative;
  
  @include breakpoint(medium) {
    padding-left: spacing(large);
  }

  @include breakpoint(small) {
    padding-left: spacing(small);
  }

  &::before {
    @include size(60px);
    background-color: $red;
    border-radius: 100%;
    align-items: center;
    color: white;
    content: counter(ol-list-counter);
    font-size: modular-scale(2);
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-right: 15px; 
    position: absolute;
    top: -18px;
    left: -33px;
    z-index: 2;

    @include breakpoint(small) {
      @include size(40px);
      font-size: modular-scale(0);
      left: -22px;
      top: -9px;
    }
  }

  &::after {
    // background-color: $red;
    border-right: solid 6px $red;
    bottom: 100%;
    content: "";
    display: block;
    position: absolute;
    left: -70px;
    height: calc(100% + #{spacing(medium)});
    width: 70px;
    z-index: 1;

    @include breakpoint(small) {
      border-width: 4px;
      height: calc(100% + #{spacing(small)});
    }
  }

  &:first-child::after {
    border-top: solid 6px $red;
    height: calc(100% + #{spacing(xx-small)});

    @include breakpoint(small) {
      border-top: 0;
      height: 70%;
    }
  }

  h4 {
    font-family: $base-font-family-bold;
    font-size: modular-scale(1);

    @include breakpoint(small) {
      font-size: modular-scale(0);
    }
  }
  
  p {
    font-size: modular-scale(0);
    margin-top: 0;

    @include breakpoint(small) {
      font-size: modular-scale(-1);
      margin-top: 5px;
    }
  }
}

.c-course__blueprint-item + .c-course__blueprint-item {
  margin-top: spacing(medium);

  @include breakpoint(small) {
    margin-top: spacing(small);
  }
}