html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $base-background-color;
  margin-top: spacing(xx-large);

  @include breakpoint(xx-large) {
    @include margin(null auto);
    max-width: $global-body-max-width;
  }

  @include breakpoint(small) {
    margin-top: 90px;
  }

  &.site-htma--home {
    margin-top: 0;
  }
}
