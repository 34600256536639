// /*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 29, 2019 */

// @font-face {
//   font-family: 'fredericka_the_greatregular';
//   src: url('/fonts/fredericka-the-great/frederickathegreat-regular-webfont.woff2') format('woff2'),
//   url('/fonts/fredericka-the-great/frederickathegreat-regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }


@font-face {
  font-family: 'gt_america_mono_regular';
  src: url('/fonts/gt-america-mono/GT-America-Mono-Regular.woff2') format('woff2'),
       url('/fonts/gt-america-mono/GT-America-Mono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_america_mono_medium';
  src: url('/fonts/gt-america-mono/GT-America-Mono-Medium.woff2') format('woff2'),
       url('/fonts/gt-america-mono/GT-America-Mono-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_america_mono_bold';
  src: url('/fonts/gt-america-mono/GT-America-Mono-Bold.woff2') format('woff2'),
       url('/fonts/gt-america-mono/GT-America-Mono-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


// get icons from ICOMOON - download a copy the css file contents here.
@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon/icomoon.eot?gul6tu');
  src:  url('/fonts/icomoon/icomoon.eot?gul6tu#iefix') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf?gul6tu') format('truetype'),
    url('/fonts/icomoon/icomoon.woff?gul6tu') format('woff'),
    url('/fonts/icomoon/icomoon.svg?gul6tu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star-filled:before {
  content: "\e90f";
  // color: #bf4025;
}
.icon-star:before {
  content: "\e905";
  // color: #bf4025;
}
.icon-community:before {
  content: "\e906";
  // color: #bf4025;
}
.icon-online:before {
  content: "\e907";
  // color: #bf4025;
}
.icon-exercises:before {
  content: "\e908";
  // color: #bf4025;
}
.icon-tutorials:before {
  content: "\e909";
  // color: #bf4025;
}
.icon-self-paced:before {
  content: "\e90a";
  // color: #bf4025;
}
.icon-office-hours:before {
  content: "\e90b";
  // color: #bf4025;
}
.icon-tools:before {
  content: "\e90c";
  // color: #bf4025;
}
.icon-terminology:before {
  content: "\e90d";
  // color: #bf4025;
}
.icon-process:before {
  content: "\e90e";
  // color: #bf4025;
}
.icon-arrow:before {
  content: "\e904";
}
.icon-articles:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-courses:before {
  content: "\e902";
}
.icon-resources:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}

