.c-nav {
  .c-header & {
    @include breakpoint(small) {
      background-color: $white;
      height: 100%;
      left: 100%;
      overflow-y: auto;
      padding: spacing(xxx-large) $global-side-spacing-small spacing(x-large);
      position: fixed;
      top: 0;
      transition: left $base-duration $base-timing;
      width: 100%;
    }
  }

  &.is-open {
    left: 0;
  }
}

.c-nav__items {
  display: flex;
  list-style: none;
  margin: 0;

  .c-header & {
    @include breakpoint(small) {
      display: block;
    }
  }
}

.c-nav__item {
  font-size: modular-scale(-1);
  position: relative;

  @include breakpoint(medium) {
    font-size: modular-scale(-2);
  }
  
  .c-header & {
    @include breakpoint(small) {
      font-size: modular-scale(1);
    }
  }
}

.c-nav__item--sub {
  width: 150px;

  .c-header & {
    font-size: modular-scale(-2);

    @include breakpoint(small) {
      font-size: modular-scale(0);
      width: auto;
    }
  }
}

.c-nav__item + .c-nav__item {
  margin-left: spacing(medium);

  @include breakpoint(medium) {
    margin-left: spacing(x-small);
  }

  @include breakpoint(small) {
    margin-left: spacing(x-small);
  }

  .c-header & {
    @include breakpoint(small) {
      @include margin(spacing(x-small) 0 null);
    }
  }
}

.c-nav__item--social + .c-nav__item--social {
  margin-left: spacing(xx-small);
}

.c-nav__item--sub + .c-nav__item--sub {
  margin-left: spacing(x-small);

  @include breakpoint(small) {
    @include margin(spacing(xx-small) 0 null);
  }
}

.c-nav__link {
  border: 0;

  &::after {
    background-color: transparent;
    content: none;
  }
  
  .c-footer & {
    color: $light-gray;

    &:active,
    &:focus,
    &:hover,
    &.is-active {
      color: $accent-color;
    }
  }
}

.c-nav__link-icon {
  font-size: 16px;
  vertical-align: middle;

  // @include breakpoint(medium) {
  //   display: none;
  // }

  &.icon-articles,
  &.icon-email {
    font-size: 12px;
  }
}

.c-nav__link-text {
  vertical-align: middle;
}

.c-nav__link-description { 
  color: $light-gray;
  font-size: inherit;
  margin-top: spacing(xxxx-small);

  @include breakpoint(small) {
    font-size: modular-scale(-2);
  }
}

.c-nav__subitems {
  left: 50%; // -200px;
  padding-top: spacing(xx-small);
  opacity: 0;
  position: absolute;
  top: spacing(xx-small);
  transition: opacity $base-duration $base-timing;
  transform: translateX(-50%);
  visibility: hidden;
  // width: 550px;

  @include breakpoint(small) {
    left: 0;
    opacity: 1;
    padding-top: spacing(xxx-small);
    position: static;
    transform: none;
    visibility: visible;
    width: auto;
  }

  .c-nav__item-has-sub:hover & {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: "";
    background-color: $white;
    border-top: $base-border;;
    border-right: $base-border;;
    height: 15px; 
    left: 50%;
    position: absolute;
    top: 12px;
    transform: translateX(-50%) rotate(-45deg);
    width: 15px; 

    @include breakpoint(small) {
      content: none;
      display: none;
    }
  }
}

.c-nav__items--sub {
  background-color: $white;
  border: $base-border;
  padding: spacing(x-small);
  text-align: center;

  @include breakpoint(small) {
    border: 0;
    padding: spacing(xxx-small) spacing(x-small);
    position: relative;
    text-align: left;
  }

  .c-nav__link {
    &::before {
      @include breakpoint(small) {
        content: "–";
        color: $light-gray;
        position: absolute;
        top: 1px;
        left: -25px;
      }
    }
  }
}