.c-share {
  // display: flex;
}

.c-share__button {
  // @include padding(spacing(xxx-small) spacing(xx-small));
  align-items: center;
  background-color: $accent-color;
  border-radius: $base-border-radius;
  color: $white;
  display: flex;
  font-size: modular-scale(-1);
  font-family: $base-font-family-medium;
  justify-content: center;
  height: $share-button-size;
  width: $share-button-size;

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background-color: shade($accent-color, 20%);
  }
}

.c-share__button-icon {
  vertical-align: middle;

  &.icon-email {
    font-size: 12px;;
  }
}

.c-share__button-text {
  vertical-align: middle;

  @include breakpoint(small) {
    display: none;
  }
}