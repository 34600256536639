@mixin breakpoint($media) {

  @if $media == xx-large {
    @media all and (min-width: $xlarge-screen) { @content; }
  }

  @if $media == x-large {
    @media all and (min-width: $large-screen) { @content; }
  }

  @if $media == large {
    @media all and (min-width: $medium-screen) and (max-width: ($large-screen - 1)) { @content; }
  }

  @if $media == medium {
    @media all and (min-width: $small-screen) and (max-width: ($medium-screen - 1)) { @content; }
  }

  @if $media == small {
    @media all and (max-width: ($small-screen - 1)) { @content; }
  }
}

@mixin svg-fit($width, $height) {
  @include position(absolute, 50% null null 50%);
  @include size($width, $height);
  transform: translate(-50%, -50%);
}


@mixin global-max-width() {
  @include margin(null auto);
  max-width: 1440px;
  width: 100%;
}

@mixin global-side-padding() {
	@include padding(null $global-side-spacing);
  
	@include breakpoint(large) {
		@include padding(null $global-side-spacing-large);
  }

	@include breakpoint(medium) {
		@include padding(null $global-side-spacing-medium);
  }

	@include breakpoint(small) {
		@include padding(null $global-side-spacing-small);
	}
}

@mixin small-text-uppercase() {
  font-family: $base-font-family-medium;
  font-size: modular-scale(-3);
  font-weight: $medium-font-weight;
  letter-spacing: $base-letter-spacing;
  text-transform: uppercase;
}