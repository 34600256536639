.c-hamburger {
  cursor: pointer;
  display: none;
  position: fixed;
  top: $global-side-spacing-small;
  right:$global-side-spacing-small;
  z-index: $highest-z-index;

  @include breakpoint(small) {
    align-items: center;
    display: flex;
  }
}

.c-hamburger__icon {
  span {
    background-color: $accent-color;
    display: block;
    width: 20px;
    height: 2px;
    background-color: $dark-gray;
    margin: 3px 0;

    .c-hamburger.is-active & {
      &:nth-child(1) {
        transform: rotate(45deg) translate(3px,3px);
        -webkit-transform: rotate(45deg) translate(3px,3px);
        -moz-transform: rotate(45deg) translate(3px,3px);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px,-4px);
        -webkit-transform: rotate(-45deg) translate(4px,-4px);
        -moz-transform: rotate(-45deg) translate(4px,-4px);
      }
    }
  }
}

.c-hamburger__current-page {
  color: $light-gray;
  font-size: modular-scale(-2);
  margin-bottom: 1px;
  padding-right: spacing(xxx-small);
  text-align: right;
  
  @media all and (max-width: 320px) {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}