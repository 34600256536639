fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  // margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  color: $medium-gray;
  display: block;
  font-size: modular-scale(-1);
  margin-bottom: spacing(xxx-small);
}

input,
select,
textarea,
button {
  display: block;
  font-family: $base-font-family;
  font-size: modular-scale(-1); //$base-font-size;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: #F5F5F5;
  border: $form-base-border;
  border-radius: $base-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  color: $dark-gray;
  height: $form-input-height;
  padding: $form-input-padding;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover,
  &:focus {
    border-color: $accent-color;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    // background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      // border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }

  &[data-error] {
    border-color: red;
    
    &:hover,
    &:focus {
      border-color: red;
    }
  }
}

textarea {
  line-height: $base-line-height;
  min-height: $form-input-height * 4;
  resize: vertical;
}

input[type="submit"],
button {
  appearance: none;
  background-color: $accent-color;
  border: 0;
  border-radius: $base-border-radius;
  box-shadow: none;
  color: $white;
  cursor: pointer;
  height: $form-input-height;
  font-family: $base-font-family-medium;
  font-weight: $medium-font-weight;
  padding: spacing(xxx-small) spacing(xx-small);
  min-width: $form-input-min-width;
  text-align: center;
  transition: background-color $base-duration $base-timing;

  @media (hover: hover) {
    &:hover {
      background-color: shade($accent-color, 25%);
      color: $white;
    }
  }
}